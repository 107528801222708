<template>
  <div id="section1">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br />
          <b-row align-v="center">
            <b-col md="3" sm="12" class="text-right">
              <font-awesome-icon :icon="payIcon" size="4x" />
            </b-col>
            <b-col md="9" sm="12" class="text-left">
              <br />
              <br />
              <h2>{{$t("payTitulo")}}</h2>
              <p>{{$t("payText")}}</p>
              <br />
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "WHAT IS THE TIP SERVICE?",
    "payText": "It is a service that allows the client to include the tip in the card payment. In this way, in a single payment, you pay the purchase amount and the desired tip amount."
  },
  "es":{
    "payTitulo": "¿QUÉ ES EL SERVICIO DE PROPINA?",
    "payText": "Es un servicio que permite al cliente incluir la propina en el pago con tarjeta. De esta forma en un único pago abona el importe de la compra y el importe de propina deseada."
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: ["fas", "globe-americas"]
    };
  }
};
</script>