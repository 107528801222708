<template>
  <div id="section2">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container>
          <br />
          <b-row align-v="center">
            <b-col md="3" sm="12" class="text-right">
              <font-awesome-icon :icon="payIcon" size="4x" />
            </b-col>
            <b-col md="9" sm="12" class="text-left">
              <br />
              <br />
              <h2>{{$t("payTitulo")}}</h2>
              <p>{{$t("payText")}}</p>
              <br />
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "BENEFITS FOR THE CARD HOLDER.",
    "payText": "The card holder has the possibility of including the tip in the card payment itself, without having to depend on cash, or wait to receive the change."
  },
  "es":{
    "payTitulo": "BENEFICIOS PARA EL TITULAR DE LA TARJETA.",
    "payText": "El titular tiene la posibilidad de incluir la propina en el propio pago con tarjeta, sin tener que depender del dinero en efectivo, ni esperar a recibir el cambio."   
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: ["fas", "credit-card"]
    };
  }
};
</script>