<template>
  <div id="section4">
    <div class="fondoGrisClaro">
      <b-container fluid>
        <b-container>
          <br />
          <h2>{{$t("payTitulo")}}</h2>
          <b-row align-v="center">
            <b-col md="3" sm="12">
              <b-img :src="payImg" />
            </b-col>
            <b-col md="9" sm="12">
              <br />
              <div v-for="(item, idx) in payList" :key="idx" class="p-3">
                <h3>{{item.titulo}}</h3>
                <p>
                  <span v-for="(item1, idx1) in item.text" :key="idx1">
                    <strong v-if="item1.txtS">{{item1.txt}}</strong>
                    <span v-else>{{item1.txt}}</span>
                  </span>
                </p>
              </div>
              <br />
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "TIP SERVICE OPERATION.",
    "payList": [
      {
        "titulo": "1. Enter the amount of the payment in the POS.",
        "text": [
          {
            "txtS": false,
            "txt":
              "Once you enter the amount of the payment, the terminal gives the option of adding a tip."
          }
        ]
      },
      {
        "titulo": "2. The card holder sees the tips screen.",
        "text": [
          {
            "txtS": false,
            "txt":
              "Show the cardholder the tip screen to choose the desired tip amount. You will be shown the following options: "
          },
          {
            "txtS": true,
            "txt": "5%, 10%, 15%, other amount."
          }
        ]
      },
      {
        "titulo": "3. The usual payment process continues.",
        "text": []
      }
    ]
  },
  "es":{
    "payTitulo": "FUNCIONAMIENTO DEL SERVICIO DE PROPINA.",
    "payList": [
      {
        "titulo": "1. Introduce en el TPV el importe de la venta.",
        "text": [
          {
            "txtS": false,
            "txt":
              "Una vez que introduces el importe de la venta, el terminal da la opción de añadir una propina."
          }
        ]
      },
      {
        "titulo": "2. El titular ve la pantalla de las propinas.",
        "text": [
          {
            "txtS": false,
            "txt":
              "Muestra al titular de la tarjeta la pantalla de propina para elegir el importe de propina deseado. Se le mostrarán las siguientes opciones: "
          },
          {
            "txtS": true,
            "txt": "5%, 10%, 15%, otro importe."
          }
        ]
      },
      {
        "titulo": "3. Continúa el proceso habitual de pago.",
        "text": []
      }
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payImg: require("@/assets/images/pay/tpv-2.png")
    };
  },
  computed: {
    payList() {
      return this.$t("payList");
    }
  }
};
</script>