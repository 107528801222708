<template>
  <div id="section3">
    <div class="fondoWhite">
      <b-container fluid>
        <b-container>
          <br />
          <b-row align-v="center">
            <b-col md="3" sm="12" class="text-right">
              <font-awesome-icon :icon="payIcon" size="4x" />
            </b-col>
            <b-col md="9" sm="12" class="text-left">
              <br />
              <br />
              <h2>{{$t("payTitulo")}}</h2>
              <ul style="padding-left: 1em;">
                <li v-for="(item, idx) in payText" :key="idx">{{item}}</li>
              </ul>
            </b-col>
          </b-row>
          <br />
        </b-container>
      </b-container>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "payTitulo": "BENEFITS FOR THE BUSINESS.",
    "payText": [
      "Increase tip reception, not having to rely on cash.",
      "Shift Control: It is possible to analyze the tip obtained by each employee and operation. Currency does not have any influence.",
      "Ease. The activation of the service in the terminal is simple and free."
    ]
  },
  "es":{
    "payTitulo": "BENEFICIOS PARA EL COMERCIO.",
    "payText": [
      "Aumento de la recepción de propina, al no tener que depender del dinero en efectivo.",
      "Control de turnos. Por cada dependiente y operación, es posible analizar la propina obtenida por cada uno moneda no influye.",
      "Facilidad. La activación del servicio en el terminal es sencilla y gratuita."
    ]
  }
}
</i18n>

<script>
export default {
  data() {
    return {
      payIcon: ["fas", "shopping-cart"]
    };
  },
  computed: {
    payText() {
      return this.$t("payText");
    }
  }
};
</script>